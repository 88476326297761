import React from "react"
import LayoutCategory from "../../components/shared/layout/layout-category"
import {useIntl} from "gatsby-plugin-intl";

const Paints = () => {
    const intl = useIntl()
    const data = {
        key: "paints",
        title: intl.formatMessage({id: `seo.paints.title`}),
        description: intl.formatMessage({id: `seo.paints.desc`}),
    }
    return (
        <LayoutCategory data={data}/>
    )
}

export default Paints
